var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.liveSpotProtocols)?_c('ui-panel',[(_vm.liveSpotProtocols.items.length === 0)?_c('ui-form-message',[_vm._v("In diesem Objekt befinden sich keine aktiven Rauchwarnmelder.")]):_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.liveSpotProtocols.items},scopedSlots:_vm._u([{key:"name.label",fn:function(){return [_vm._v("Name"),_c('br'),_vm._v("Ort")]},proxy:true},{key:"name",fn:function(ref){
var row = ref.row;
return [_c('domain-ui-tree-node-path',{attrs:{"tree-nodes":row.spot.path.items,"context-tree-node":{ id: _vm.treeNodeId }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var primaryTreeNode = ref.primaryTreeNode;
return [_c('ui-clickable',{on:{"click":_vm.onSpotClick}},[_c('div',{staticClass:"text-overflow"},[_vm._v(_vm._s(row.spot.name)+" "+_vm._s(row.spot.roomName)),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(primaryTreeNode ? primaryTreeNode.name : '–'))])])])]}}],null,true)})]}},{key:"serial.label",fn:function(){return [_vm._v("Gerätenummer"),_c('br'),_vm._v("Hersteller")]},proxy:true},{key:"serial",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.serialOf(row))),_c('br'),_c('span',{staticClass:"text-light"},[_vm._v(_vm._s(_vm.manufacturerOf(row)))])]}},_vm._l((_vm.checkColumns),function(ref){
var name = ref.name;
return {key:name,fn:function(ref){
var row = ref.row;
return [_c('ui-icon-state-indicator',{attrs:{"state":_vm.stateOf(row, name),"size":15}})]}}})],null,true)})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"value":_vm.currentPage,"total-pages":_vm.totalPages},on:{"input":function($event){return _vm.$emit('update:currentPage', $event)}}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }