







































import { Column } from '@/features/ui/table/model';
import { ObjectProp } from '@/util/prop-decorators';
import { TreeNodeType } from '@/types/iot-portal';
import { find, get } from 'lodash';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { CHECK_LABELS } from '../../../util/checks';
import { AppSmokeDetectorTreeNodeProtocolsViewQuery } from './__generated__/AppSmokeDetectorTreeNodeProtocolsViewQuery';
import InfoItem from './InfoItem.vue';
import GREEN from './green.svg';
import GRAY from './gray.svg';
import RED from './red.svg';

type Protocol = AppSmokeDetectorTreeNodeProtocolsViewQuery['protocols']['items'][number];

@Component({ components: { InfoItem } })
export default class PreviewPanel extends Vue {
  @ObjectProp(true)
  private readonly protocol!: Protocol;

  private moment = moment;

  private readonly baseColumns: readonly Column[] = [
    { name: 'serial', field: 'spot.serial', label: 'Seriennummer' },
    { name: 'room', field: 'spot.roomName', label: 'Raum' },
  ];

  private get checkColumns(): readonly Column[] {
    return Object.entries(CHECK_LABELS).map(([name, label]) => ({ name, label, align: 'center' }));
  }

  private get columns(): readonly Column[] {
    return [...this.baseColumns, ...this.checkColumns];
  }

  private get title(): string {
    return 'Wartungsprotokoll ' + moment(this.protocol.creationDate).format('YYYY');
  }

  private get apartment(): string {
    return get(find(this.protocol.context, { type: TreeNodeType.PropertySubdivision }), 'name', 'n/a');
  }

  private stateOf(row: Protocol['spots'][number], name: string): string {
    const ok = row.checks.find((check) => check.name === name)?.ok;

    return ok === undefined ? GRAY : ok ? GREEN : RED;
  }
}
