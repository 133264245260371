import { render, staticRenderFns } from "./ProtocolsView.vue?vue&type=template&id=71013b93&scoped=true&lang=pug&"
import script from "./ProtocolsView.vue?vue&type=script&lang=ts&"
export * from "./ProtocolsView.vue?vue&type=script&lang=ts&"
import style0 from "./ProtocolsView.vue?vue&type=style&index=0&id=71013b93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71013b93",
  null
  
)

export default component.exports