








import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class InfoItem extends Vue {
  @StringProp(true)
  private readonly label!: string;

  @StringProp(true)
  private readonly value!: string;
}
