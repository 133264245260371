













































import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { kebabCase, last } from 'lodash';
import moment from 'moment';
import VueJsonCsv from 'vue-json-csv';
import { Component, Mixins, Vue } from 'vue-property-decorator';
import { CHECK_LABELS } from '../../../util/checks';
import {
  AppSmokeDetectorTreeNodeProtocolsViewQuery,
  AppSmokeDetectorTreeNodeProtocolsViewQueryVariables,
} from './__generated__/AppSmokeDetectorTreeNodeProtocolsViewQuery';
import query from './view.gql';

type Protocol = AppSmokeDetectorTreeNodeProtocolsViewQuery['protocols']['items'][number];

@Component({
  apollo: {
    protocols: {
      query,
      variables(): AppSmokeDetectorTreeNodeProtocolsViewQueryVariables {
        return { treeNodeId: this.treeNodeId, skip: this.skip, take: this.take };
      },
    },
  },
  components: { VueJsonCsv },
  data() {
    return { protocols: undefined, protocolPage: undefined };
  },
})
export default class ProtocolsView extends Mixins(TreeNodeLocationGeneratorMixin, PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly protocols?: AppSmokeDetectorTreeNodeProtocolsViewQuery['protocols'];
  private protocolPage?: Protocol;

  private readonly columns: readonly Column[] = [
    { name: 'creationDate', label: 'Datum', width: 70 },
    { name: 'context', label: 'Datei' },
    { name: 'spots', label: 'Anzahl der Geräte', align: 'right', width: 160 },
    { name: 'preview', label: 'Vorschau', align: 'center', width: 68 },
    { name: 'export', label: 'Export', align: 'right', width: 100 },
  ];

  public readonly $refs!: { protocolPage: Vue };

  private compileExportData(protocol: Protocol): Record<string, string>[] {
    const checkNames = [...new Set(protocol.spots.flatMap(({ checks }) => checks).map(({ name }) => name))]
      .map((name) => CHECK_LABELS[name] ?? name)
      .sort((a, b) => a.localeCompare(b));

    return protocol.spots.map((spot) => {
      const item: Record<string, string> = {
        Name: spot.name,
        Raum: spot.roomName,
        Seriennummer: spot.serial,
        Hersteller: spot.manufacturer,
        Geräteadresse: spot.deviceAddress,
      };

      for (const name of checkNames) {
        item[name] = 'Unbekannt';
      }

      for (const { name, ok } of spot.checks) {
        item[CHECK_LABELS[name] ?? name] = ok ? 'OK' : 'Problem!';
      }

      return item;
    });
  }

  private generateExportFilename(protocol: Protocol, extension?: string): string {
    const context = last(protocol.context);
    const date = moment(protocol.creationDate).format('YYYY-MM-DD--HH-mm-ss');

    if (!context) {
      return `rauchwarnmelder-protokoll--${date}${extension ?? ''}`;
    }

    return `rauchwarnmelder-protokoll-${kebabCase(context.name)}--${date}${extension ?? ''}`;
  }

  private async onExportAsPdf(protocol: Protocol, hide?: () => Promise<void>): Promise<void> {
    this.protocolPage = protocol;
    const [html2pdf] = await Promise.all([import('html2pdf.js'), this.$nextTick()]);

    html2pdf
      .default()
      .set({
        margin: 0,
        filename: this.generateExportFilename(protocol, '.pdf'),
        html2canvas: { scale: 2 },
      })
      .from(this.$refs.protocolPage.$el)
      .save();

    await hide?.();
  }
}
