























import AppRedirectionMixin from '@/features/core/components/mixins/app-redirection';
import GroupsMixin from '@/features/core/components/mixins/groups';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import query from './view.gql';
import {
  AppSmokeDetectorTreeNodeViewQuery,
  AppSmokeDetectorTreeNodeViewQueryVariables,
} from './__generated__/AppSmokeDetectorTreeNodeViewQuery';

@Component({
  apollo: {
    treeNodes: {
      query,
      variables(this: TreeNodeView): AppSmokeDetectorTreeNodeViewQueryVariables {
        return { treeNodeId: this.treeNodeId };
      },
    },
  },
  data() {
    return { treeNodes: undefined };
  },
})
export default class TreeNodeView extends Mixins(AppRedirectionMixin, GroupsMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly treeNodes?: AppSmokeDetectorTreeNodeViewQuery['treeNodes'];
}
