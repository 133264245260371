









































import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { PaginationQueryStringMixin } from '@/features/core/components/mixins/pagination-query-string';
import { Column } from '@/features/ui/table/model';
import { StringProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { CHECK_LABELS } from '../../../util/checks';
import {
  AppSmokeDetectorTreeNodeLiveViewQuery,
  AppSmokeDetectorTreeNodeLiveViewQueryVariables,
} from './__generated__/AppSmokeDetectorTreeNodeLiveViewQuery';
import query from './view.gql';

type SpotProtocol = AppSmokeDetectorTreeNodeLiveViewQuery['liveSpotProtocols']['items'][number];

@Component({
  apollo: {
    liveSpotProtocols: {
      query,
      variables(this: LiveView): AppSmokeDetectorTreeNodeLiveViewQueryVariables {
        return { treeNodeId: this.treeNodeId, skip: this.skip, take: this.take };
      },
    },
  },
  data() {
    return { liveSpotProtocols: undefined };
  },
})
export default class LiveView extends Mixins(TreeNodeLocationGeneratorMixin, PaginationQueryStringMixin) {
  @StringProp(true)
  private readonly treeNodeId!: string;

  private readonly liveSpotProtocols?: AppSmokeDetectorTreeNodeLiveViewQuery['liveSpotProtocols'];

  private readonly baseColumns: readonly Column[] = [{ name: 'name' }, { name: 'serial' }];

  private get checkColumns(): readonly Column[] {
    return [...new Set(this.liveSpotProtocols?.items.flatMap(({ checks }) => checks).map(({ name }) => name))].map(
      (name) => ({
        name,
        label: CHECK_LABELS[name],
        align: 'center',
      }),
    );
  }

  private get columns(): readonly Column[] {
    return [...this.baseColumns, ...this.checkColumns];
  }

  private serialOf(row: SpotProtocol): string {
    return row.spot.activeDeviceMount?.serial ?? '–';
  }

  private manufacturerOf(row: SpotProtocol): string {
    return row.spot.activeDeviceMount?.manufacturer ?? '–';
  }

  private stateOf(row: SpotProtocol, name: string): string {
    return row.checks.find((check) => check.name === name)?.ok ? 'GREEN' : 'RED';
  }

  private onSpotClick(spot: SpotProtocol['spot']): void {
    this.$router.push({
      name: 'AppManager/TreeNode/Spots/Spot',
      params: { treeNodeId: spot.path.items.slice(-1)[0].id, spotId: spot.id },
    });
  }
}
