var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"header"},[_c('div',[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"sub-title"},[_vm._v("Protokoll zur Installation und Prüfung von Rauchwarnmeldern gemäß DIN 14676")])]),_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/images/logos/vodafone.png"),"alt":"Vodafone","width":"65"}})]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"maintenance"},[_c('div',{staticClass:"label"},[_vm._v("Wartungsinformationen")]),_c('div',{staticClass:"info-items"},[_c('info-item',{attrs:{"label":"Inspektionsdatum","value":_vm.moment(_vm.protocol.creationDate).format('L')}}),_c('info-item',{attrs:{"label":"Letzte Inspektion","value":"n/a"}}),_c('info-item',{attrs:{"label":"Zeitraum zwischen der Inspektion","value":"n/a"}})],1)]),_c('div',{staticClass:"spot"},[_c('div',{staticClass:"label"},[_vm._v("Geräteinformationen")]),_c('div',{staticClass:"info-items"},[_c('info-item',{attrs:{"label":"Buchungskreis","value":"n/a"}}),_c('info-item',{attrs:{"label":"Adresse","value":"n/a"}}),_c('info-item',{attrs:{"label":"Etage","value":"n/a"}}),_c('info-item',{attrs:{"label":"Wohnung","value":_vm.apartment}}),_c('info-item',{attrs:{"label":"Wirtschaftseinheit","value":"n/a"}})],1)])]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"check-points"},[_c('div',{staticClass:"label"},[_vm._v("Prüfungspunkte")]),_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.protocol.spots},scopedSlots:_vm._u([_vm._l((_vm.checkColumns),function(ref){
var name = ref.name;
return {key:name,fn:function(ref){
var row = ref.row;
return [_c('img',{attrs:{"src":_vm.stateOf(row, name),"alt":""}})]}}})],null,true)},[_vm._l((_vm.columns),function(ref){
var name = ref.name;
var label = ref.label;
return _c('template',{slot:(name + ".label")},[_c('div',{staticClass:"theader"},[_vm._v(_vm._s(label))])])})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }