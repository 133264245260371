var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.protocols)?_c('ui-panel',[(_vm.protocols.items.length === 0)?_c('ui-form-message',[_vm._v("Für dieses Objekt wurden noch keine Rauchwarnmelderprotokolle generiert.")]):_c('ui-flex',[_c('ui-cell',{attrs:{"basis":1}},[(_vm.protocolPage)?_c('protocol-page',{ref:"protocolPage",staticStyle:{"display":"none"},attrs:{"data":_vm.protocolPage}}):_vm._e(),_c('ui-table',{attrs:{"columns":_vm.columns,"rows":_vm.protocols.items},scopedSlots:_vm._u([{key:"creationDate",fn:function(ref){
var value = ref.value;
return [_c('ui-date',{attrs:{"date":value}})]}},{key:"context",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value[value.length - 1].name))]}},{key:"spots",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.length))]}},{key:"preview",fn:function(ref){
var row = ref.row;
return [_c('ui-wizard',{attrs:{"title":_vm.generateExportFilename(row)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var show = ref.show;
return [_c('ui-clickable',{on:{"click":show}},[_c('img',{attrs:{"src":require("./preview.svg"),"alt":"Vorschau"}})])]}},{key:"content",fn:function(ref){
var hide = ref.hide;
return [_c('ui-flex',{staticStyle:{"max-width":"540px"}},[_c('ui-cell',{attrs:{"basis":1}},[_c('div',{staticClass:"page-wrapper"},[_c('protocol-page',{attrs:{"protocol":row}})],1)]),_c('ui-cell',{attrs:{"grow":0,"push-right":""}},[_c('ui-wizard-button',{on:{"click":function($event){return _vm.onExportAsPdf(row, hide)}}},[_vm._v("Download PDF")])],1)],1)]}}],null,true)})]}},{key:"export",fn:function(ref){
var row = ref.row;
return [_c('ui-button',{attrs:{"inline":""},on:{"click":function($event){return _vm.onExportAsPdf(row)}}},[_c('img',{staticClass:"arrow",attrs:{"src":require("./export.svg"),"alt":"Export"}}),_vm._v("PDF")]),_vm._v(_vm._s(' \xa0 ')),_c('vue-json-csv',{attrs:{"data":_vm.compileExportData(row),"name":_vm.generateExportFilename(row, '.csv')}},[_c('ui-button',{attrs:{"inline":""}},[_c('img',{staticClass:"arrow",attrs:{"src":require("./export.svg"),"alt":"Export"}}),_vm._v("CSV")])],1)]}}],null,false,1286578559)})],1),(_vm.totalPages > 1)?_c('ui-cell',{attrs:{"basis":1}},[_c('ui-pagination',{attrs:{"value":_vm.currentPage,"total-pages":_vm.totalPages},on:{"input":function($event){return _vm.$emit('update:currentPage', $event)}}})],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }