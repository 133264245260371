











import { App } from '@/features/core/model';
import { RootGetter } from '@/features/core/store';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppView extends Vue {
  @RootGetter
  private readonly app!: App;
}
